import { FC, ReactNode } from "react"
import { overrideTailwindClasses } from "tailwind-override"
import { classNames } from "../../../helpers/classNames"

type Props = {
  children: ReactNode
  className?: string
  testId?: string
}

export const DashboardCard: FC<Props> = ({ children, className, testId }) => {
  return (
    <div
      className={overrideTailwindClasses(
        classNames("shadow-lg lg:shadow-none border rounded-lg pt-4 pb-8 px-4 lg:px-10 h-full", className)
      )}
      data-test={testId}
    >
      {children}
    </div>
  )
}
