import { Button, LinearProgress, useMediaQuery, useTheme } from "@mui/material"
import { DataGridPro, GridRenderCellParams } from "@mui/x-data-grid-pro"
import { useRouter } from "next/router"
import { FC, useContext, useEffect, useState } from "react"
import {
  BiCar,
  BiCheck,
  BiDotsHorizontalRounded,
  BiDuplicate,
  BiEdit,
  BiGroup,
  BiLayer,
  BiPencil,
  BiSortAlt2,
} from "react-icons/bi"
import { Organization, Project, useActivateProjectMutation } from "../../../../graphql/generated/client-types-and-hooks"
import { classNames } from "../../../../helpers/classNames"
import { colors } from "../../../../helpers/colors"
import { useGetProjectProgressSummary } from "../../../../hooks/queries/useGetProjectProgressSummary"
import { useModalProps } from "../../../../hooks/useModalProps"
import { DrawerContext } from "../../../../providers/DrawerProvider"
import { PermissionsContext } from "../../../../providers/PermissionsProvider/PermissionsProvider"
import { PickPlus } from "../../../../types/helpers"
import { IconLabel } from "../../../IconLabel"
import StandardModal from "../../../Modals/StandardModal"
import { errorSnack, successSnack } from "../../../Notistack/ThemedSnackbars"
import { ProjectBadge } from "../../../ProjectBadge"
import { ProjectUnitReportForm } from "../../../ProjectUnitReportForm"
import { QuickMenu } from "../../../QuickMenu"
import { MenuItem } from "../../../QuickMenuMui"
import { RenderIf } from "../../../RenderIf"
import { SkeletonContainer } from "../../../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../../Skeletons/SkeletonElement"
import { DrawerLink } from "../../Drawer/DrawerLink"
import { ProjectEditDrawer } from "../../Drawer/ProjectEditDrawer"
import { SortDirection } from "../../Team/TeamList"
import { DuplicateProjectModal } from "../DuplicateProjectmodal"
import { PermissionedLink } from "../PermissionedLink"
import { ProjectStatusPill } from "../ProjectStatusPill"
import { ProgressMiniBar } from "../Tasks/ProgressMiniBar"

type DataGridProject = PickPlus<
  Project,
  | "id"
  | "code"
  | "isArchived"
  | "isComplete"
  | "isDefault"
  | "imageUrl"
  | "name"
  | "assetsCount"
  | "userCount"
  | "hasReportableUnit"
>

type Props = {
  organization?: PickPlus<Organization, "id" | "imageUrl" | "name">
  projects: DataGridProject[]
  loading: boolean
  onAssignTeam: (project: PickPlus<Project, "id" | "isArchived">) => void
  setSortDirection: (value?: SortDirection | null) => void
}

/**
 * Renders a data grid component to display project information.
 * @returns A data grid component displaying project information.
 */

export const ProjectDataGrid: FC<Props> = ({ organization, projects, loading, onAssignTeam, setSortDirection }) => {
  const [_, activateProjectMutation] = useActivateProjectMutation()
  const { push: pushDrawer, clearAll } = useContext(DrawerContext)
  const { hasPermissionTo } = useContext(PermissionsContext)
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("lg"))
  const router = useRouter()
  const duplicateProjectModalProps = useModalProps("Duplicate Project")
  const [projectSelected, setProjectSelected] = useState<PickPlus<Project, "id" | "name"> | undefined>()

  const setSortOrder = (value?: SortDirection | null) => {
    setSortDirection(value)
  }

  useEffect(() => {
    // Reset projectSelected when the duplicate project modal is closed
    if (duplicateProjectModalProps.isOpen === false) setProjectSelected(undefined)
  }, [duplicateProjectModalProps.isOpen])

  return (
    <>
      <DataGridPro
        columns={[
          {
            field: "name",
            headerName: "Name",
            flex: 2,
            minWidth: 120,
            sortable: true,
            filterable: false,
            renderCell: ({ row: project }: GridRenderCellParams<DataGridProject>) => (
              <PermissionedLink permissions={["project:read"]} projectId={project.id} href={`/projects/${project.id}`}>
                <ProjectBadge organization={organization} project={project} />
              </PermissionedLink>
            ),
          },
          {
            field: "progress",
            headerName: "Progress",
            flex: 1,
            minWidth: 90,
            maxWidth: 150,
            sortable: false,
            filterable: false,
            renderCell: ({ row: project }: GridRenderCellParams<DataGridProject>) =>
              !project.isArchived ? (
                <QuickMenu
                  menuButtonClassName="p-0 hover:bg-transparent"
                  menuButtonProps={{ disableRipple: true }}
                  items={
                    hasPermissionTo("project:read")
                      ? [
                          [
                            {
                              value: "View project",
                              onClick: () => router.push(`/projects/${project.id}/tasks`),
                              Icon: BiLayer,
                            },
                          ],
                        ]
                      : []
                  }
                >
                  <ProjectProgressMiniBar id={project.id} />
                </QuickMenu>
              ) : (
                <ProjectProgressMiniBar id={project.id} />
              ),
          },
          {
            field: "team",
            headerName: "Team",
            flex: 1,
            minWidth: 90,
            maxWidth: 150,
            sortable: false,
            filterable: false,
            renderCell: ({ row: project }: GridRenderCellParams<DataGridProject>) =>
              !project.isArchived && project.userCount > 0 && hasPermissionTo("project:read") ? (
                <QuickMenu
                  items={[
                    [
                      {
                        value: "View team",
                        onClick: () => router.push(`/projects/${project.id}/team`),
                        Icon: BiGroup,
                      },
                    ],
                  ]}
                  className="w-full"
                  menuButtonClassName="w-full text-left p-2.5 bg-gray-100 hover:bg-gray-200 hover:rounded transition-all"
                >
                  <IconLabel icon={BiGroup} label={project.userCount} />
                </QuickMenu>
              ) : (
                <Button
                  className="w-full p-2.5 bg-gray-100 hover:bg-gray-200 hover:rounded transition-all"
                  color="inherit"
                  disabled
                >
                  <IconLabel icon={BiGroup} label={project.userCount} />
                </Button>
              ),
          },
          {
            field: "assets",
            headerName: "Assets",
            flex: 1,
            minWidth: 90,
            maxWidth: 150,
            sortable: false,
            filterable: false,
            renderCell: ({ row: project }: GridRenderCellParams<DataGridProject>) =>
              !project.isArchived && project.assetsCount > 0 && hasPermissionTo("project:read") ? (
                <QuickMenu
                  items={[
                    [
                      {
                        value: "View assets",
                        onClick: () => router.push(`/projects/${project.id}/assets`),
                        Icon: BiCar,
                      },
                    ],
                  ]}
                  className="w-full"
                  menuButtonClassName="w-full text-left p-2.5 bg-gray-100 hover:bg-gray-200 hover:rounded transition-all"
                >
                  <IconLabel icon={BiCar} label={project.assetsCount} />
                </QuickMenu>
              ) : (
                <Button
                  className="w-full p-2.5 bg-gray-100 hover:bg-gray-200 hover:rounded transition-all"
                  color="inherit"
                  disabled
                >
                  <IconLabel icon={BiCar} label={project.assetsCount} />
                </Button>
              ),
          },
          {
            field: "status",
            headerName: "Status",
            flex: 1,
            minWidth: 90,
            maxWidth: 150,
            sortable: false,
            filterable: false,
            renderCell: ({ row: project }: GridRenderCellParams<DataGridProject>) => (
              <ProjectStatusPill project={project} />
            ),
          },
          {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            minWidth: 90,
            maxWidth: 150,
            sortable: false,
            filterable: false,
            renderCell: ({ row: project }: GridRenderCellParams<DataGridProject>) =>
              project.hasReportableUnit ? (
                <RenderIf permissionsInclude="task:report" context={{ projectId: project.id }}>
                  <DrawerLink
                    component={<ProjectUnitReportForm projectId={project.id} handleClose={() => clearAll()} />}
                  >
                    <Button component="span" variant="contained" color="secondary" className="px-6">
                      Report
                    </Button>
                  </DrawerLink>
                </RenderIf>
              ) : null,
          },
          {
            field: "menu",
            headerName: "",
            flex: 0.5,
            editable: false,
            resizable: false,
            sortable: false,
            filterable: false,
            hideable: false,
            align: "right",
            renderCell: ({ row: project }: GridRenderCellParams<DataGridProject>) => {
              const projectActions: MenuItem[][] = []

              if (hasPermissionTo("project:read")) {
                projectActions.push([
                  {
                    value: "View project",
                    onClick: () => router.push(`/projects/${project.id}/tasks`),
                    Icon: BiLayer,
                  },
                  {
                    value: "View team",
                    onClick: () => router.push(`/projects/${project.id}/team`),
                    Icon: BiGroup,
                  },
                  {
                    value: "View assets",
                    onClick: () => router.push(`/projects/${project.id}/assets`),
                    Icon: BiCar,
                  },
                ])

                projectActions.push([
                  {
                    value: "Report",
                    drawer: {
                      href: "",
                      component: <ProjectUnitReportForm projectId={project.id} handleClose={clearAll} />,
                    },
                    Icon: BiPencil,
                  },
                  {
                    value: "Assign",
                    onClick: () => onAssignTeam(project),
                    Icon: BiSortAlt2,
                    iconStyles: "rotate-90 w-5 h-5",
                  },
                ])
              }

              const editProjectActions: MenuItem[] = []

              if (hasPermissionTo("project:update")) {
                editProjectActions.push({
                  value: "Edit",
                  onClick: () => pushDrawer(<ProjectEditDrawer project={project} />),
                  Icon: BiEdit,
                })
              }

              if (hasPermissionTo("project:create")) {
                editProjectActions.push({
                  value: "Duplicate Project",
                  onClick: () => {
                    setProjectSelected(project)
                    duplicateProjectModalProps.handleOpenModal()
                  },
                  Icon: BiDuplicate,
                })
              }

              if (editProjectActions.length > 0) {
                projectActions.push(editProjectActions)
              }

              const archivedProjectActions: MenuItem[][] = []

              if (hasPermissionTo("project:archive"))
                archivedProjectActions.push([
                  {
                    value: "Activate project",
                    onClick: async () => {
                      const result = await activateProjectMutation({ id: project.id })

                      result.error
                        ? errorSnack("Failed to activate project")
                        : successSnack("Project activated successfully.")
                    },

                    Icon: BiCheck,
                  },
                ])

              return !project.isDefault ? (
                <QuickMenu
                  className={classNames(
                    "h-10 w-10 flex items-center justify-center flex-none rounded-full transition-colors md:mt-1",
                    "md:h-12 md:w-12",
                    "hover:bg-gray-50"
                  )}
                  items={project.isArchived ? archivedProjectActions : projectActions}
                  buttonShape="round"
                >
                  <BiDotsHorizontalRounded className="w-6 h-6" />
                </QuickMenu>
              ) : null
            },
          },
        ]}
        rows={projects}
        rowCount={projects.length}
        loading={loading}
        density="comfortable"
        filterMode="server"
        sortingMode="server"
        paginationMode="server"
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        onSortModelChange={([model]) => setSortOrder(model?.sort)}
        columnVisibilityModel={{
          name: true,
          progress: !mobile,
          team: !mobile,
          assets: !mobile,
          status: !mobile,
          actions: !mobile,
        }}
        scrollEndThreshold={200}
        slots={{ loadingOverlay: LinearProgress }}
        sx={{
          border: "none",
          "& .MuiDataGrid-cell:focus": { outline: "none" },
          "& .MuiDataGrid-cell:focus-within": { outline: "none" },
          "& .MuiDataGrid-columnHeader:focus": { outline: "none" },
          "& .MuiDataGrid-columnHeader:focus-within": { outline: "none" },
        }}
      />
      {projectSelected && (
        <StandardModal {...duplicateProjectModalProps}>
          <DuplicateProjectModal
            modalProps={duplicateProjectModalProps}
            projectId={projectSelected.id}
            projectName={projectSelected.name}
            onSuccess={() => {
              setProjectSelected(undefined)
            }}
          />
        </StandardModal>
      )}
    </>
  )
}

/**
 * Renders a mini progress bar for a project.
 * @param {string} props.id - The ID of the project.
 * @returns {JSX.Element} A progress mini bar component for the project.
 */

export const ProjectProgressMiniBar: FC<{ id: string; variant?: string }> = ({ id, variant }) => {
  const { data, fetching } = useGetProjectProgressSummary(id)

  const { completionPercentage: percentage } = data

  return fetching ? (
    <SkeletonContainer>
      <SkeletonElement className="h-6" />
    </SkeletonContainer>
  ) : (
    <ProgressMiniBar
      variant={variant}
      progressPercent={percentage > 1 ? 100 : percentage * 100}
      disabled={data?.completionPercentage === undefined}
      color={colors.teal[600]}
      noHours={data.noHours}
    />
  )
}
