import { FC } from "react"
import { ActiveProjectsWidget } from "./BlockWidgets/ActiveProjectsWidget"
import { CurrentAssignmentWidget } from "./BlockWidgets/CurrentAssignmentWidget"
import { NotificationsWidget } from "./BlockWidgets/NotificationsWidget"
import { TimeClockWidget } from "./BlockWidgets/TimeClockWidget"
import { AssetsLinkWidget } from "./LinkWidgets/AssetsLinkWidget"
import { ProjectsLinkWidget } from "./LinkWidgets/ProjectsLinkWidget"
import { TeamLinkWidget } from "./LinkWidgets/TeamLinkWidget"
import { MyAssetsWidget } from "./MyAssetsWidget"
import { PermissionedWidget } from "./PermissionedWidget"
import { ActiveProjectsCountWidget } from "./ValueWidgets/ActiveProjectsCountWidget"
import { ClockedInCountWidget } from "./ValueWidgets/ClockedInCountWidget"
import { WeeklyHoursClockedWidget } from "./ValueWidgets/WeeklyHoursClockedWidget"

export const HomeWidgets: FC = () => {
  return (
    <div className="flex flex-col md:gap-y-6 gap-y-8">
      <div className="grid grid-cols-1 md:hidden gap-4 mb-4">
        <PermissionedWidget permission="project:read:*">
          <ProjectsLinkWidget />
        </PermissionedWidget>
        <PermissionedWidget permission="user:read:*">
          <TeamLinkWidget />
        </PermissionedWidget>
        <PermissionedWidget permission="asset:read:*">
          <AssetsLinkWidget />
        </PermissionedWidget>
      </div>

      <div className="hidden md:grid md:grid-cols-3 gap-4">
        <PermissionedWidget permission="timeEntry:clockInOut:*">
          <ClockedInCountWidget />
        </PermissionedWidget>
        <PermissionedWidget permission="timeEntry:clockInOut:*">
          <WeeklyHoursClockedWidget />
        </PermissionedWidget>
        <PermissionedWidget permission="project:read:*">
          <ActiveProjectsCountWidget />
        </PermissionedWidget>
      </div>

      <div className="grid lg:grid-cols-5 gap-4">
        <PermissionedWidget
          className="lg:col-span-2"
          permission={["timeEntry:clockInOut:*", "timeEntry:clockInOut:self"]}
        >
          <TimeClockWidget />
        </PermissionedWidget>
        <div className="lg:col-span-3">
          <NotificationsWidget />
        </div>
      </div>
      <PermissionedWidget permission={["project:read:*", "project:read:assigned-project"]}>
        <CurrentAssignmentWidget />
      </PermissionedWidget>
      <PermissionedWidget permission={["asset:read:assigned-self"]}>
        <MyAssetsWidget />
      </PermissionedWidget>
      <PermissionedWidget permission="project:read:*">
        <ActiveProjectsWidget />
      </PermissionedWidget>

      <div className="flex md:hidden gap-4 overflow-x-scroll">
        <PermissionedWidget permission="timeEntry:clockInOut:*">
          <ClockedInCountWidget />
        </PermissionedWidget>
        <PermissionedWidget permission="timeEntry:clockInOut:*">
          <WeeklyHoursClockedWidget />
        </PermissionedWidget>
        <PermissionedWidget permission="project:read:*">
          <ActiveProjectsCountWidget />
        </PermissionedWidget>
      </div>
    </div>
  )
}
