import { FC } from "react"
import { BiLayer } from "react-icons/bi"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { pluralize } from "../../../helpers/pluralize"
import { useHandleError } from "../../../hooks/useHandleError"
import { LinkWidget } from "./LinkWidget"

const ProjectLinksQueryDocument = graphql(`
  query ProjectsLinkWidget {
    myOrganization {
      id
      activeProjectsCount
    }
  }
`)

export const ProjectsLinkWidget: FC = () => {
  const [{ data, error }] = useQuery({
    query: ProjectLinksQueryDocument,
    requestPolicy: "cache-first",
  })

  const projectCount = data?.myOrganization.activeProjectsCount || 0

  useHandleError(error, "Unable to retrieve project count")

  return (
    <LinkWidget
      Icon={BiLayer}
      title={"Projects"}
      description={`${projectCount} ${pluralize("project", projectCount)}`}
      href={"/projects/active"}
    />
  )
}
