import { FC, ReactNode } from "react"
import { RiArrowRightCircleFill } from "react-icons/ri"
import { H4 } from "../../Elements"

type Props = {
  title: string | ReactNode
  withIcon?: boolean
}

export const DashboardCardHeader: FC<Props> = ({ title, withIcon }) => {
  return (
    <div className="flex items-center justify-between mb-4">
      <H4 className="mb-2">{title}</H4>
      {withIcon && <RiArrowRightCircleFill className="h-7 w-7 text-blue-600" />}
    </div>
  )
}
