import { FC } from "react"
import { BiCalendarWeek } from "react-icons/bi"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { pluralize } from "../../../helpers/pluralize"
import { ValueWidget } from "./ValueWidget"

const WeeklyHoursClockedWidgetDocument = graphql(`
  query WeeklyHoursClockedWidget {
    myOrganization {
      id
      secondsClockedForCurrentWeek
    }
  }
`)
export const WeeklyHoursClockedWidget: FC = () => {
  const [{ fetching, data }] = useQuery({
    query: WeeklyHoursClockedWidgetDocument,
    requestPolicy: "cache-first",
  })
  const hours = Math.round((data?.myOrganization.secondsClockedForCurrentWeek || 0) / 3600)

  return (
    <ValueWidget
      href={`/team`}
      hrefPermissions={["timeEntry:clockInOut"]}
      Icon={BiCalendarWeek}
      label={`${pluralize("Hour", hours)} this week`}
      value={hours}
      isLoading={fetching}
    />
  )
}
