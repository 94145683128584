import { FC } from "react"
import { BiStopwatch } from "react-icons/bi"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { ValueWidget } from "./ValueWidget"

export const ClockedInCountWidget: FC = () => {
  const [{ fetching, data }] = useQuery({
    query: graphql(`
      query ClockedInCountWidget {
        myOrganization {
          id
          clockedInUserCount
          userCount
        }
      }
    `),
  })

  return (
    <ValueWidget
      href={`/team`}
      hrefPermissions={["timeEntry:clockInOut"]}
      Icon={BiStopwatch}
      label={"Clocked in"}
      value={`${data?.myOrganization.clockedInUserCount || "-"} / ${data?.myOrganization.userCount || "-"}`}
      isLoading={fetching}
    />
  )
}
