import { Button } from "@mui/material"
import Link from "next/link"
import { useRouter } from "next/router"
import { FC, useContext } from "react"
import { BiCar, BiGroup } from "react-icons/bi"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { H5 } from "../../Elements"
import { DashboardCard } from "../../Partials/Dashboard/DashboardCard"
import { DashboardCardHeader } from "../../Partials/Dashboard/DashboardCardHeader"
import { DrawerLink } from "../../Partials/Drawer/DrawerLink"
import { ProjectProgressMiniBar } from "../../Partials/Projects/ProjectList/DataGrid"
import { ProjectStatusPill } from "../../Partials/Projects/ProjectStatusPill"
import { ProjectImage } from "../../ProjectImage"
import { ProjectUnitReportForm } from "../../ProjectUnitReportForm"
import { QuickMenuMui } from "../../QuickMenuMui"
import { RenderIf } from "../../RenderIf"
import { SkeletonBlock } from "../../Skeletons/SkeletonBlock"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"

const ActiveProjectsWidgetDocument = graphql(`
  query ActiveProjectsWidget2 {
    myOrganization {
      id
      activeProjectsCount
      imageUrl
      name
    }

    projectsByStatus(status: active, limit: 3, includeDefault: false) {
      id
      assetsCount
      code
      endDate
      hasReportableUnit
      imageUrl
      isArchived
      isComplete
      isDefault
      name
      startDate
      userCount
    }
  }
`)

export const ActiveProjectsWidget: FC = () => {
  const { clearAll } = useContext(DrawerContext)
  const router = useRouter()
  const [{ data, fetching }] = useQuery({ query: ActiveProjectsWidgetDocument })

  const activeProjectList = data?.projectsByStatus || []

  return fetching ? (
    <SkeletonContainer>
      <SkeletonBlock className="grow h-80" />
    </SkeletonContainer>
  ) : (
    <>
      <DashboardCardHeader
        title={
          <div className="flex gap-x-2">
            <span>Active Projects</span>
            <span className="text-gray-400">{data?.myOrganization.activeProjectsCount || 0}</span>
          </div>
        }
      />
      <div className="mb-6 flex flex-col gap-y-6 content-center">
        {activeProjectList.map((project) => (
          <DashboardCard key={project.id} className="p-4 md:p-8">
            <div className="flex flex-col gap-y-4 md:gap-y-6">
              <Link key={project.id} href={`/projects/${project.id}`} className="w-fit">
                <div className="flex flex-col md:flex-row gap-x-4 gap-y-2">
                  <div className="flex justify-center items-center rounded h-10 w-10 bg-gray-100">
                    <ProjectImage organization={data?.myOrganization} project={project} width="w-10" height="h-10" />
                  </div>
                  <div className="flex items-center gap-x-2">
                    <H5 className="cursor-pointer hover:underline underline-offset-1 my-0">{project.name}</H5>
                    <H5 className="text-gray-400 my-0">
                      # {project.isDefault ? data?.myOrganization?.name : project.code}
                    </H5>
                    <ProjectStatusPill project={project} />
                  </div>
                </div>
              </Link>

              <div className="flex flex-col md:flex-row gap-y-4 md:justify-between">
                <div className="flex flex-col md:flex-row gap-2">
                  <QuickMenuMui
                    className="w-full md:w-[88px] justify-start p-2 bg-gray-100 hover:bg-gray-200 hover:rounded transition-all gap-2"
                    items={[
                      [
                        {
                          value: "View team",
                          onClick: () => router.push(`/projects/${project.id}/team`),
                          Icon: BiGroup,
                        },
                      ],
                    ]}
                  >
                    <BiGroup className="w-6 h-6 p-1 rounded bg-gray-800 text-white" />
                    <p className="text-gray-800 truncate leading-tight">{project.userCount}</p>
                  </QuickMenuMui>
                  <QuickMenuMui
                    className="w-full md:w-[88px] justify-start p-2 bg-gray-100 hover:bg-gray-200 hover:rounded transition-all gap-2"
                    items={[
                      [
                        {
                          value: "View assets",
                          onClick: () => router.push(`/projects/${project.id}/assets`),
                          Icon: BiCar,
                        },
                      ],
                    ]}
                  >
                    <BiCar className="w-6 h-6 p-1 rounded bg-gray-800 text-white" />
                    <p className="text-gray-800 truncate leading-tight">{project.assetsCount}</p>
                  </QuickMenuMui>
                </div>
                <div className="flex flex-row gap-x-2">
                  <ProjectProgressMiniBar variant="gray" id={project.id} />
                  {project.hasReportableUnit && (
                    <RenderIf permissionsInclude="task:update">
                      <DrawerLink
                        component={<ProjectUnitReportForm projectId={project.id} handleClose={() => clearAll()} />}
                      >
                        <Button component="span" variant="contained" color="secondary" disableElevation fullWidth>
                          Report
                        </Button>
                      </DrawerLink>
                    </RenderIf>
                  )}
                </div>
              </div>
            </div>
          </DashboardCard>
        ))}
      </div>
      <Link href={"/projects"}>
        <Button variant="contained" color="secondary" disableElevation fullWidth size="small">
          View all
        </Button>
      </Link>
    </>
  )
}
