import { useQuery } from "urql"
import { graphql } from "../../graphql/generated/gql"
import { getProjectProgressSummary } from "../../helpers/summarizeProgressFromTaskList"

// Queries for the progress of a specified project.
const ProjectProgressQueryDocument = graphql(`
  query ProjectProgress($projectId: String!) {
    tasks(projectId: $projectId) {
      id
      isComplete
      estimatedHours
      isDefault
      timeEntriesSumDurationInSeconds
      unitGoalsSumProgress
      unitGoalsSumTargetQuantity
    }
  }
`)

/**
 * Custom hook to fetch and summarize the progress of a specified project.
 * @param projectId - The ID of the project.
 * @param enabled - Boolean indicating whether the query execution is active or not (default is true).
 * @returns An object containing the summarized progress data, along with fetching status, potential errors, and a refetch function.
 */
export const useGetProjectProgressSummary = (projectId: string, enabled = true) => {
  const [{ data, fetching, error }, refetch] = useQuery({
    query: ProjectProgressQueryDocument,
    variables: { projectId },
    pause: !enabled,
  })

  const tasks = data?.tasks || []

  return {
    data: getProjectProgressSummary(tasks),
    fetching,
    error,
    refetch,
  }
}
