import { FC } from "react"
import { H2 } from "../components/Elements"
import Layout from "../components/Layout/Layout"
import { HomeWidgets } from "../components/Widgets/HomeWidgets"
import { useCurrentUser } from "../providers/PermissionsProvider/currentUserProvider"

export { default as getServerSideProps } from "../helpers/setupCSRF"

const Home: FC = () => {
  const { firstName } = useCurrentUser()

  return (
    <Layout title="Home">
        <div className="flex flex-col gap-4 md:gap-6">
          <H2>Hi, {firstName}</H2>
          <HomeWidgets />
        </div>
    </Layout>
  )
}

export default Home
