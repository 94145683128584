import { Button, Typography } from "@mui/material"
import Link from "next/link"
import { FC, useContext } from "react"
import { BiLayer, BiListUl, BiNavigation, BiPencil, BiShow, BiSortAlt2 } from "react-icons/bi"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { useModalProps } from "../../../hooks/useModalProps"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { getDirectionsMapUrl } from "../../Maps/helpers"
import StandardModal from "../../Modals/StandardModal"
import { successSnack } from "../../Notistack/ThemedSnackbars"
import { DashboardCard } from "../../Partials/Dashboard/DashboardCard"
import { DashboardCardHeader } from "../../Partials/Dashboard/DashboardCardHeader"
import { DrawerLink } from "../../Partials/Drawer/DrawerLink"
import { TaskDrawer } from "../../Partials/Drawer/TaskDrawer"
import { ReassignUserTaskForm } from "../../Partials/User/ReassignUserTaskForm/ReassignUserTaskForm"
import { ProjectImage } from "../../ProjectImage"
import { ProjectUnitReportForm } from "../../ProjectUnitReportForm"
import { RenderIf } from "../../RenderIf"

const GetCurrentAssignment = graphql(`
  query CurrentAssignment {
    currentUser {
      id
      currentProject {
        id
        isArchived
        isComplete
        imageUrl
        location {
          lat
          lng
          radius
        }
        name
        code
      }
      currentProjectId
      currentTask {
        id
        name
        projectId
      }
      currentTaskId
      firstName
      imageUrl
      jobTitle
      lastName
      projectId
      taskId
      organization {
        id
        imageUrl
        name
      }
    }
  }
`)

export const CurrentAssignmentWidget: FC = () => {
  const { clearAll } = useContext(DrawerContext)
  const reassignUserTaskFormModalProps = useModalProps("Reassign")
  const { push: pushDrawer } = useContext(DrawerContext)

  const [{ data }, refetch] = useQuery({
    query: GetCurrentAssignment,
  })

  if (!data) {
    return null
  }

  const { currentProjectId, currentProject, currentTask, organization } = data.currentUser

  return (
    <DashboardCard>
      <DashboardCardHeader title="Current Assignment" />
      <div className="flex flex-col items-center md:items-start gap-y-4 md:gap-y-6">
        <Link key={currentProjectId} href={`/projects/${currentProjectId}`} className="w-full md:w-fit">
          <div className="flex items-center md:flex-row gap-x-4 gap-y-2">
            <div className="flex justify-center rounded h-10 w-10 bg-gray-100">
              <ProjectImage organization={organization} project={currentProject} width="w-10" height="h-10" />
            </div>
            <div className="flex flex-col w-full md:flex-row gap-x-2">
              <div className="flex basis-full md:basis-1/2 items-center whitespace-nowrap gap-x-2">
                <BiLayer />
                <Typography
                  variant="h5"
                  className="cursor-pointer hover:underline underline-offset-1 my-0 text-sm md:text-xl"
                >
                  {currentProject.name}
                </Typography>
              </div>
              <div className="flex basis-full md:basis-1/2 items-center whitespace-nowrap gap-x-2">
                <BiListUl className="text-gray-500" />
                <DrawerLink href="" component={<TaskDrawer taskId={currentTask.id} />}>
                  <Typography
                    variant="h5"
                    className="text-gray-500 hover:underline underline-offset-1 my-0 text-sm md:text-xl"
                  >
                    {currentTask.name}
                  </Typography>
                </DrawerLink>
              </div>
            </div>
          </div>
        </Link>
        <div className="flex flex-col w-full md:flex-row gap-y-4 gap-x-2">
          <Button
            variant="contained"
            color="secondary"
            startIcon={<BiShow />}
            onClick={() => pushDrawer(<TaskDrawer taskId={currentTask.id} />)}
          >
            View task
          </Button>

          <RenderIf permissionsInclude="task:update">
            <div className="flex flex-col md:flex-row gap-y-4 gap-x-2">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<BiPencil />}
                disableElevation
                fullWidth
                onClick={() =>
                  pushDrawer(<ProjectUnitReportForm projectId={currentProjectId} handleClose={() => clearAll()} />)
                }
              >
                Report
              </Button>
            </div>
          </RenderIf>

          <RenderIf permissionsInclude="user:assign">
            <Button
              variant="contained"
              color="secondary"
              startIcon={<BiSortAlt2 />}
              onClick={reassignUserTaskFormModalProps.handleOpenModal}
            >
              Reassign
            </Button>
            {reassignUserTaskFormModalProps.isOpen && (
              <StandardModal className="overflow-y-visible" {...reassignUserTaskFormModalProps}>
                <ReassignUserTaskForm
                  onCancel={() => {
                    reassignUserTaskFormModalProps.handleCloseModal()
                  }}
                  onSuccess={() => {
                    successSnack("Selected users have been successfully reassigned.")
                    reassignUserTaskFormModalProps.handleCloseModal()
                    refetch()
                  }}
                  preselectedUsers={[data.currentUser]}
                  projectId={currentProjectId}
                />
              </StandardModal>
            )}
          </RenderIf>

          {currentProject.location && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<BiNavigation />}
              href={getDirectionsMapUrl(currentProject.location)}
              target="_blank"
            >
              Directions
            </Button>
          )}
        </div>
      </div>
    </DashboardCard>
  )
}
export default CurrentAssignmentWidget
