import { Box, Button, LinearProgress, Typography } from "@mui/material"
import { FC } from "react"
import { colors } from "../../../../helpers/colors"
import { NAButton } from "../../../NAbutton"

const getStyles = (isOverage: boolean, group: boolean, color: string) => {
  if (isOverage) {
    return group ? { backgroundColor: colors.teal[600] } : { backgroundColor: colors.green[600] }
  } else {
    return { backgroundColor: color }
  }
}

const getTypographyColor = (progressPercent: number, isOverage: boolean, group: boolean, color: string) => {
  if (progressPercent < 1) {
    return colors.gray[300]
  } else if (isOverage) {
    return group ? colors.teal[600] : colors.green[600]
  } else {
    return color
  }
}

export const ProgressMiniBar: FC<{
  variant?: string
  progressPercent: number
  disabled?: boolean
  color?: string
  noHours?: boolean
  showNA?: boolean
  group?: boolean
}> = ({
  variant,
  progressPercent,
  disabled = false,
  noHours = false,
  color = colors.sky[500],
  showNA = false,
  group = false,
}) => {
  const isOverage = progressPercent >= 100
  const determinateStyles = getStyles(isOverage, group, colors.gray[300])
  const customStyles = getStyles(isOverage, group, color)
  const typographyColor = getTypographyColor(progressPercent, isOverage, group, color)

  return noHours ? (
    <NAButton />
  ) : (
    <Button
      variant={variant === "gray" ? "contained" : "text"}
      color={variant === "gray" ? "secondary" : "primary"}
      sx={{ minWidth: "125px", height: "40px", position: "relative", right: "10px" }}
      disabled={disabled}
    >
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          color="success"
          value={progressPercent}
          sx={{
            transform: progressPercent > 100 ? "rotate(180deg)" : undefined,
            "&.MuiLinearProgress-determinate": { borderRadius: "18px", ...determinateStyles },
            "& .MuiLinearProgress-barColorSuccess": { borderRadius: "18px", ...customStyles },
          }}
        />
      </Box>
      {showNA ? (
        <NAButton />
      ) : (
        <Typography variant="body2" sx={{ color: typographyColor }}>
          {Math.round(progressPercent) || 0}%
        </Typography>
      )}
    </Button>
  )
}
