import { FC } from "react"
import { BiCheckCircle } from "react-icons/bi"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { pluralize } from "../../../helpers/pluralize"
import { useHandleError } from "../../../hooks/useHandleError"
import { ValueWidget } from "./ValueWidget"

const ActiveProjectsCountWidgetQueryDocument = graphql(`
  query ActiveProjectsCountWidget {
    myOrganization {
      id
      activeProjectsCount
    }
  }
`)

export const ActiveProjectsCountWidget: FC = () => {
  const [{ data, error, fetching }] = useQuery({
    query: ActiveProjectsCountWidgetQueryDocument,
    requestPolicy: "cache-first",
  })

  useHandleError(error, "Unable to retrieve project count")

  const activeProjectsCount = data?.myOrganization.activeProjectsCount || 0

  return (
    <ValueWidget
      value={activeProjectsCount}
      isLoading={fetching}
      Icon={BiCheckCircle}
      href="/projects/active"
      hrefPermissions={["project:read"]}
      label={`Active ${pluralize("Project", activeProjectsCount)}`}
    />
  )
}
