import Link from "next/link"
import { FC } from "react"
import { RiArrowRightCircleFill } from "react-icons/ri"
import { TailwindIcon } from "../../../types/tailwind"

type Props = {
  Icon: TailwindIcon
  title: string
  description: string
  href: string
}

export const LinkWidget: FC<Props> = ({ Icon, href, title, description }) => {
  return (
    <Link href={href}>
      <div className={"flex gap-4 items-center cursor-pointer"}>
        <div className={"rounded-full bg-blue-600 h-12 w-12 flex items-center justify-center"}>
          <Icon className={"h-6 w-6 bg-blue-600 text-white"} />
        </div>
        <div className={"flex flex-col grow leading-tight justify-center"}>
          <p>{title}</p>
          <p className={"text-gray-400 text-sm"}>{description}</p>
        </div>
        <RiArrowRightCircleFill className={"h-7 w-7 text-blue-600"} />
      </div>
    </Link>
  )
}
