import { FC } from "react"
import { BiGroup } from "react-icons/bi"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { pluralize } from "../../../helpers/pluralize"
import { useHandleError } from "../../../hooks/useHandleError"
import { SkeletonBlock } from "../../Skeletons/SkeletonBlock"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"
import { LinkWidget } from "./LinkWidget"

const UserCountQuery = graphql(`
  query GetMyOrgUserCount {
    myOrganization {
      id
      userCount
    }
  }
`)

export const TeamLinkWidget: FC = () => {
  const [{ data, fetching, error }] = useQuery({
    query: UserCountQuery,
  })

  const userCount = data?.myOrganization?.userCount || 0

  useHandleError(error, "Unable to get team member count")

  if (fetching) {
    return (
      <SkeletonContainer>
        <SkeletonBlock className={"h-12 grow"} />
      </SkeletonContainer>
    )
  }

  return (
    <LinkWidget
      Icon={BiGroup}
      title={"Team"}
      description={`${userCount} team ${pluralize("member", userCount)}`}
      href={"/team"}
    />
  )
}
