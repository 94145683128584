import { FC } from "react"
import { Project } from "../../../graphql/generated/client-types-and-hooks"
import Pill from "../../Pill"

export const ProjectStatusPill: FC<{ project: Pick<Project, "isArchived" | "isComplete">; className?: string }> = ({
  project,
  className,
}) => {
  return (
    <>
      {project.isArchived && (
        <Pill color="red" className={className}>
          Archived
        </Pill>
      )}
      {project.isComplete && (
        <Pill color="green" className={className}>
          Complete
        </Pill>
      )}
      {!project.isArchived && !project.isComplete && (
        <Pill color="blue" className={className}>
          Active
        </Pill>
      )}
    </>
  )
}
