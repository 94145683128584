import { Box, Grid } from "@mui/material"
import { FC, useEffect } from "react"
import { useQuery } from "urql"
import { graphql } from "../../graphql/generated/gql"
import { colors } from "../../helpers/colors"
import { useAssetListGroupConsolidation } from "../../hooks/useAssetListGroupConsolidation"
import { useCurrentUser } from "../../providers/PermissionsProvider/currentUserProvider"
import { AssetBadge } from "../AssetBadge"
import { DashboardCardHeader } from "../Partials/Dashboard/DashboardCardHeader"

const MyAssetsQuery = graphql(`
  query UserAssetsList($userId: String!) {
    assets(deleted: false, userId: $userId, includeGroupedAssets: false) {
      id
      active
      assetChildCount
      assetGroupId
      assignableId
      assignableType
      companyAssetNumber
      compositeKey
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
    }
    assetGroups(userId: $userId) {
      assetGroupId
      assignableId
      assignableType
      compositeKey
      count
      status
      groupParent {
        id
        name
        imageUrl
        ownershipType
      }
    }
  }
`)

export const MyAssetsWidget: FC = () => {
  const { id: userId } = useCurrentUser()
  const [{ data }, getAssets] = useQuery({
    query: MyAssetsQuery,
    variables: { userId },
    pause: true,
  })

  const assets = useAssetListGroupConsolidation(data)

  useEffect(() => {
    if (userId && !data) {
      getAssets()
    }
  }, [assets, data, getAssets, userId])

  if (!assets?.length) {
    return null
  }

  return assets?.length > 0 ? (
    <>
      <DashboardCardHeader title="My Assets" />
      <Grid container spacing={1.5}>
        {assets?.map((asset) => (
          <Grid key={asset.id} item lg={12 / 5} md={3} xs={12}>
            <Box borderRadius="8px" alignItems="center" padding="10px" bgcolor={colors.gray[100]}>
              <AssetBadge asset={asset} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  ) : null
}
