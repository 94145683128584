import Link from "next/link"
import { FC, ReactNode, useContext } from "react"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { CheckedPermission } from "../../../types/Permission"
import { errorSnack } from "../../Notistack/ThemedSnackbars"

type PermissionLinkProps = {
  children: ReactNode
  className?: string
  "data-test"?: string
  href: string
  permissions: CheckedPermission | CheckedPermission[] | undefined
  projectId: string
}

export const PermissionedLink: FC<PermissionLinkProps> = ({ children, href, permissions, projectId, ...props }) => {
  const { hasPermissionTo } = useContext(PermissionsContext)

  const unauthorized = () => {
    errorSnack("You don't have access to view this project.")
  }

  if (hasPermissionTo(permissions, { projectId })) {
    return (
      <Link href={href} {...props}>
        {children}
      </Link>
    )
  }

  return (
    <div className="cursor-not-allowed" onClick={unauthorized}>
      {children}
    </div>
  )
}
