import { useRouter } from "next/router"
import { FC, useContext } from "react"
import { classNames } from "../../../helpers/classNames"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { CheckedPermission } from "../../../types/Permission"
import { TailwindIcon } from "../../../types/tailwind"
import { SkeletonBlock } from "../../Skeletons/SkeletonBlock"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"

type ValueWidgetProps = {
  Icon: TailwindIcon
  label: string
  value: string | number | undefined
  href?: string
  hrefPermissions?: CheckedPermission[]
  isLoading: boolean
}

export const ValueWidget: FC<ValueWidgetProps> = ({
  Icon,
  label,
  href = "",
  value,
  hrefPermissions = [],
  isLoading,
}) => {
  const { hasPermissionTo } = useContext(PermissionsContext)
  const router = useRouter()

  if (isLoading) {
    return (
      <SkeletonContainer>
        <SkeletonBlock className={"grow h-32"} />
      </SkeletonContainer>
    )
  }

  return (
    <div
      className={classNames(
        "flex grow flex-col max-md:min-w-[242px] rounded-lg bg-blue-600 p-8 lg:px-10 text-white gap-2 transition-all hover:bg-blue-700",
        href && "cursor-pointer"
      )}
      onClick={() => {
        if (hasPermissionTo(hrefPermissions) && href) {
          router.push(href)
        }
      }}
    >
      <p className={"text-3xl font-bold"}>{value}</p>
      <div className={"flex gap-x-2 uppercase"}>
        <Icon className={"h-6 w-6"} />
        {label}
      </div>
    </div>
  )
}
