import { FC } from "react"
import { BiCar } from "react-icons/bi"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { pluralize } from "../../../helpers/pluralize"
import { LinkWidget } from "./LinkWidget"

const AssetsLinkWidgetQuery = graphql(`
  query AssetsLinkWidgetQuery {
    assets(deleted: false) {
      id
      name
    }
  }
`)

export const AssetsLinkWidget: FC = () => {
  const [{ data }] = useQuery({
    query: AssetsLinkWidgetQuery,
  })

  const assetsCount = data?.assets.length || 0

  return (
    <LinkWidget
      Icon={BiCar}
      title={"Assets"}
      description={`${assetsCount} ${pluralize("asset", assetsCount)}`}
      href={"/assets"}
    />
  )
}
