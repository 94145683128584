import { Button } from "@mui/material"
import { format } from "date-fns"
import { FC, useContext } from "react"
import { useQuery } from "urql"
import { dashboardTestSelectors } from "../../../cypress/support/testSelectors"
import { graphql } from "../../../graphql/generated/gql"
import { classNames } from "../../../helpers/classNames"
import { useUpdatingUserClockedInHoursAndMinutes } from "../../../hooks/useUpdatingUserClockedInHoursAndMinutes"
import { useUpdatingUserClockedInHoursAndMinutesForWeek } from "../../../hooks/useUpdatingUserClockedInHoursAndMinutesForWeek"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { ClockInOut } from "../../ClockInOut"
import { H4 } from "../../Elements"
import { DashboardCard } from "../../Partials/Dashboard/DashboardCard"
import { UserDrawer } from "../../Partials/Drawer/UserDrawer"
import Pill from "../../Pill"
import { RenderIf } from "../../RenderIf"

const TimeClockWidgetQuery = graphql(`
  query TimeClockWidget {
    currentUser {
      id
      projectId
      taskId

      archived
      firstName
      isClockedIn
      isOnBreak
      lastName
      secondsClockedSinceOrgMidnight
      secondsClockedThisWeek

      latestTimeEntry {
        id
        endAt
        startAt
      }
    }
  }
`)
export const TimeClockWidget: FC = () => {
  const { push: pushDrawer } = useContext(DrawerContext)
  const [{ data }] = useQuery({ query: TimeClockWidgetQuery })

  const { hoursClockedIn, minutesClockedIn, secondsClockedIn } = useUpdatingUserClockedInHoursAndMinutes(
    data?.currentUser,
    true
  )

  const totalWeeklyTime = useUpdatingUserClockedInHoursAndMinutesForWeek(data?.currentUser, false)

  const latestTimeEntry = data?.currentUser.latestTimeEntry
  const dateOfLastActiveTimeEntry = data?.currentUser.isClockedIn
    ? new Date()
    : latestTimeEntry?.endAt || latestTimeEntry?.startAt

  return (
    <RenderIf permissionsInclude={["timeEntry:clockInOut"]} context={{ userId: data?.currentUser.id }}>
      <DashboardCard testId={dashboardTestSelectors.myTime}>
        <div className="flex items-baseline gap-x-2 mb-6">
          <H4 className="mb-2">My Time</H4>
          {dateOfLastActiveTimeEntry && (
            <div className="text-xl text-gray-400">{format(dateOfLastActiveTimeEntry, "eee, MMM do")}</div>
          )}
          {data?.currentUser?.isOnBreak && <Pill color="violet">Break</Pill>}
        </div>
        <div className="flex flex-col gap-y-6">
          <div className="flex md:gap-x-12 gap-x-8">
            <div className="flex flex-col gap-y-2">
              <div className="text-sm font-bold uppercase">Today</div>
              <div
                className={classNames(
                  "flex items-end gap-3 text-5xl font-bold transition-colors text-[32px] ",
                  data?.currentUser?.isClockedIn ? "text-blue-600" : "text-gray-400",
                  data?.currentUser.isOnBreak ? "text-purple-600" : ""
                )}
              >
                {data?.currentUser?.secondsClockedSinceOrgMidnight
                  ? `${hoursClockedIn}:${minutesClockedIn}:${secondsClockedIn}`
                  : "-"}
              </div>
            </div>
            <div className="flex flex-col gap-y-2">
              <div className="text-sm font-bold uppercase">Weekly</div>
              <div
                className={classNames(
                  "flex items-end gap-3 text-5xl font-semibold transition-colors text-[32px]",
                  data?.currentUser?.isClockedIn ? "text-blue-600" : "text-gray-400",
                  !data?.currentUser?.isOnBreak ? "text-gray-400" : ""
                )}
              >
                {data?.currentUser?.secondsClockedThisWeek
                  ? `${totalWeeklyTime.hoursClockedIn}:${totalWeeklyTime.minutesClockedIn}`
                  : "-"}
              </div>
            </div>
          </div>
          <div className="flex flex-row-reverse justify-end gap-x-2 gap-y-4">
            <div className="flex items-center space-x-2">
              <Button
                fullWidth
                onClick={() => pushDrawer(<UserDrawer userId={data?.currentUser?.id!} tab="time-cards" />)}
              >
                View pay period
              </Button>
            </div>
            {data?.currentUser && <ClockInOut user={data?.currentUser!} />}
          </div>
        </div>
      </DashboardCard>
    </RenderIf>
  )
}
