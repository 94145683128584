import { useEffect, useRef, useState } from "react"
import { User } from "../graphql/generated/client-types-and-hooks"
import { secondsToDuration } from "../helpers/time-utility"
import { PickPlus } from "../types/helpers"
import { padNumber } from "./useUpdatingUserClockedInHoursAndMinutes"

export const useUpdatingUserClockedInHoursAndMinutesForWeek = (
  user: PickPlus<User, "isClockedIn" | "secondsClockedThisWeek"> | undefined,
  withSeconds = false
) => {
  const [counter, setCounter] = useState<number>(0)
  const [lastKnownUserSecondsClocked, setLaskKnownUserSecondsClocked] = useState<number | null>()
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)
  const intervalRef = useRef<number>(0)
  intervalRef.current = counter

  const [duration, setDuration] = useState({ hoursClockedIn: "0", minutesClockedIn: "0", secondsClockedIn: "0" })

  const setHoursAndMinutesClocked = (seconds: number) => {
    const duration = secondsToDuration(seconds)
    setDuration({
      hoursClockedIn: String(duration.hours || 0),
      minutesClockedIn: padNumber(duration.minutes || 0),
      secondsClockedIn: padNumber(duration.seconds || 0),
    })
  }

  // Run the timer loop
  useEffect(() => {
    const clockInterval = withSeconds ? 1 : 60

    const intervalId = setInterval(
      () => {
        setCounter(intervalRef.current + clockInterval)
      },
      clockInterval * 1000 // 1 or 60 seconds
    )

    setTimer(intervalId)
    return () => clearInterval(intervalId)
  }, [user?.secondsClockedThisWeek, user?.isClockedIn, withSeconds])

  // Run the 'update' effect
  useEffect(() => {
    // If we are clocked in... we shouldn't be running the timer and can remove it if it exists
    if (user && !user.isClockedIn && timer) {
      clearInterval(timer)
      setTimer(null)
    }

    if (lastKnownUserSecondsClocked !== user?.secondsClockedThisWeek) {
      // Ok, if we've got new data, just reset our 'while rendered' interval offset
      setCounter(0)
    }

    setHoursAndMinutesClocked(
      user?.isClockedIn
        ? user?.secondsClockedThisWeek + intervalRef.current
        : user?.secondsClockedThisWeek || 0
    )

    setLaskKnownUserSecondsClocked(user?.secondsClockedThisWeek)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.isClockedIn, user?.secondsClockedThisWeek, counter])

  return duration
}